import {DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'

// New semantic colors should be added to this file

// General
export const disabledBackground = NamedColors.ASH
export const primaryBrandColor = DefaultVariantsColor['primary']
export const success = DefaultVariantsColor['success']
export const link = DefaultVariantsColor['link']
export const warning = DefaultVariantsColor['warning']
export const error = DefaultVariantsColor['error']
export const borderColor = NamedColors.ASH
export const dividerColor = NamedColors.ASH
export const separatorColor = NamedColors.ASH
export const primaryBackgroundColor = NamedColors.WHITESMOKE
export const drawerHeaderBackground = NamedColors.WHITE
export const backgroundWithOpacity = DefaultVariantsColor['black']
export const shadowWithOpacity = DefaultVariantsColor['black']
export const transparent = NamedColors.TRANSPARENT

// Push notifications
export const pushNotificationColor = DefaultVariantsColor['primary']

// Dropdown
export const dropDownBackground = DefaultVariantsColor['white']
export const dropDownBorderColor = NamedColors.ASH

// Text
export const primaryText = DefaultVariantsColor['textPrimary']
export const textFieldActiveColor = DefaultVariantsColor['link']
export const disabledText = DefaultVariantsColor['disabled']
export const darkGrayText = NamedColors.SILVER
export const descriptionLabel = NamedColors.SILVER
export const disabledPrimaryButtonText = DefaultVariantsColor['textDisabled']

// Loaders
export const progressBarColor = DefaultVariantsColor['link']
export const spinnerColor = DefaultVariantsColor['primary']

// Page
export const genericPageBackground = DefaultVariantsColor['white']
export const subheaderText = NamedColors.BLACK

// Tile
export const tileBackground = DefaultVariantsColor['white']
export const tileShadowColor = NamedColors.ASH
export const tileSecondaryTitle = NamedColors.SILVER
export const tileDividerBackground = NamedColors.WHITESMOKE

// Datepicker
export const selectedDateColor = DefaultVariantsColor['primary']

// Radio button
export const radioSelectRightIconDisabled = DefaultVariantsColor['disabled']
export const radioSelectSelectedBorder = DefaultVariantsColor['primary']
export const radioSelectDisabledBorder = NamedColors.ASH
export const radioSelectNormalBorder = DefaultVariantsColor['primary']

// Icon Button
export const disabledIconButtonColor = NamedColors.ASH

//New Button
export const primaryButtonNormalBackground = DefaultVariantsColor['black']
export const primaryButtonFocusBackground = NamedColors.OCEAN
export const secondaryButtonNormalBackground = DefaultVariantsColor['white']
export const disabledButtonNormalBackground = DefaultVariantsColor['disabled']
export const errorButtonNormalBackground = DefaultVariantsColor['white']
export const progressiveButtonNormalBackground = NamedColors.OCEAN
export const progressiveButtonFocusBackground = NamedColors.OCEAN
export const progressBarButtonBackground = NamedColors.SLATE
export const errorButtonNormalBorder = DefaultVariantsColor['error']
export const primaryButtonFocusBorder = NamedColors.PRODUCT_BLUE
export const errorButtonFocusBorder = DefaultVariantsColor['error']

// Generic old button
export const primaryButtonTextColor = DefaultVariantsColor['inverse']
export const pressedButtonColor = NamedColors.OCEAN
export const borderedButtonBackground = DefaultVariantsColor['white']
export const borderedButtonBorderColor = NamedColors.ASH
export const shadowColorStackedButton = DefaultVariantsColor['black']
export const disabledButtonBackground = NamedColors.ASH
export const buttonBaseContainer = DefaultVariantsColor['primary']
export const buttonBaseDisabledBackground = NamedColors.ASH
export const tertiaryButtonDisabled = NamedColors.WHITESMOKE

// SecondaryActionBox
export const secondaryActionBoxBackgroundColor = NamedColors.SLATE

// Feature related
export const disbursementMethodSelectedColor = DefaultVariantsColor['primary']
export const paymentScheduleBackground = NamedColors.WHITESMOKE
export const signingBlockBorder = NamedColors.ASH
export const bankContainterLoaderBackground = NamedColors.ASH
export const unselectedCTABackground = NamedColors.ASH

// Elevation
export const elevationInsetColor = NamedColors.ASH
export const elevationShadowColor = NamedColors.SILVER

// TextInput
export const textInputBackgroundColor = NamedColors.WHITE
export const textInputBorderColor = NamedColors.ASH
export const textInputDefaultColor = NamedColors.SILVER
export const textInputEmptyColor = NamedColors.SILVER
export const textInputErrorOutlineColor = NamedColors.PRODUCT_VERMILLION
export const textInputErrorOuterOutlineColor = NamedColors.BLUSH
export const textInputActiveOutlineColor = NamedColors.PRODUCT_BLUE
export const textInputActiveOuterOutlineColor = NamedColors.SKY
export const textInputLabelActiveColor = NamedColors.PRODUCT_BLUE

// InfoCapsule
export const infoCapsuleText = NamedColors.SILVER
export const infoCapsuleBorderColor = NamedColors.ASH
export const infoCapsuleBackground = NamedColors.WHITE

// Modals
export const modalBackgroundColor = NamedColors.SEMITRANSPARENT

// BottomSheet
export const bottomSheetDragIndicatorColor = NamedColors.ASH

// Dashboard
export const modernDashboardHeaderBackground = NamedColors.OCEAN
export const modernDashboardHeaderBackgroundLight = NamedColors.WHITESMOKE

// Alert
export const alertSuccessBorder = NamedColors.WHITE
export const alertSuccessTitle = NamedColors.BLACK

export const alertInfoBorder = NamedColors.WHITE
export const alertInfoTitle = NamedColors.PRODUCT_BLUE

export const alertWarningBorder = NamedColors.PRODUCT_GOLD
export const alertWarningTitle = NamedColors.BLACK

export const alertErrorBorder = NamedColors.PRODUCT_VERMILLION
export const alertErrorTitle = NamedColors.PRODUCT_VERMILLION

// Progress Bar
export const fineProgressBarColor = DefaultVariantsColor['link']
export const thickProgressBarColor = NamedColors.CREAM
export const disabledProgressBarColor = NamedColors.ASH

// Attempts List Header
export const headerSeparatorColor = NamedColors.ASH

// Pagination
export const paginationInactiveStepColor = NamedColors.ASH

// Carousel
export const dotActiveColor = NamedColors.PRODUCT_BLUE
export const dotInactiveColor = NamedColors.SILVER

// Menu
export const menuItemChevronColor = DefaultVariantsColor['link']

// Thumbnail
export const thumbnailDeleteIconColor = DefaultVariantsColor['error']
export const thumbnailDeleteIconUnderlayColor = NamedColors.PRODUCT_VERMILLION

// Signup/Login Page
export const loginPageBackground = NamedColors.SLATE
export const loginPageBackgroundBottom = NamedColors.WHITE

// Card
export const cardActionRowBorderColor = NamedColors.ASH

// PaymentMethodAccount
export const paymentAccountMethodBorderColor = NamedColors.ASH

// TabList
export const tabListBorderColor = NamedColors.BLACK
export const tabListBorderColorDisabled = NamedColors.ASH
export const pendingTransactionPillColor = NamedColors.ASH
export const pendingTransactionPillTextColor = NamedColors.BLACK
export const failedTransactionPillColor = NamedColors.VERMILLION

export const lightBackgroundColor = NamedColors.ASH

// Card Transactions
export const transactionStatusPillColor = NamedColors.SKY

// Basic Card
export const virtualCardBackground = NamedColors.WHITESMOKE

// Loan Selection
export const loanSummaryLegalTextColor = NamedColors.SILVER

// Settlements
export const settlementOnboardingBackground = NamedColors.SLATE
export const settlementPaymentBackground = NamedColors.WHITE
export const inactivePaymentText = NamedColors.SILVER

// Card Dashboard Payment
export const suspendedFraudInfoCapsuleText = NamedColors.CREAM

// Card How to Pay
export const selectButtonBackground = NamedColors.ASH
export const selectButtonBackgroundSelected = NamedColors.PRODUCT_BLUE
export const selectButtonBorder = NamedColors.ASH
export const recommendedBoxBackground = NamedColors.GREEN

//Resgistration or Login
export const safeAreaViewTopBackground = NamedColors.PRODUCT_BLUE

// DisbursementCTA
export const disbursementBorderColor = NamedColors.SKY

// MISC
export const creditCardInputPlaceholderTextColor = '#E6E7EA' // Primary black at 10%
export const mainBodyText = '#828794' // Primary black at 50%

// DateBubbleList
export const nextDateBubbleBorderColor = NamedColors.PRODUCT_BLUE
export const upcomingDateBubbleBorderColor = NamedColors.PRODUCT_BLUE_ALPHA_20
export const missedDateBubbleBorderColor = NamedColors.GOLD
export const failedDateBubbleBorderColor = NamedColors.PRODUCT_VERMILLION
export const inProgressDateBubbleBorderColor = NamedColors.PRODUCT_LIME
