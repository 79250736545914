import React, {ReactNode, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack/lib/typescript/src/types'

import {LoanPaidOffTile} from 'src/products/loans/Dashboard/LoanPaidOffTile/LoanPaidOffTile'
import {LoanProgressTile} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile'
import LoanRejectedTile from 'src/products/loans/Dashboard/LoanRejectedTile/LoanRejectedTile'
import {LoanExpiredTile} from 'src/products/loans/Dashboard/LoanExpiredTile/LoanExpiredTile'
import {LoanUnmetStateRequirementsTile} from 'src/products/loans/Dashboard/LoanUnmetStateRequirementsTile/LoanUnmetStateRequirementsTile'
import {LoanCancelledTile} from 'src/products/loans/Dashboard/LoanCancelledTile/LoanCancelledTile'
import {LoanEligibleToApplyTile} from 'src/products/loans/Dashboard/LoanEligibleToApplyTile/LoanEligibleToApplyTile'
import {LoanChargedOffSettledTile} from 'src/products/loans/Dashboard/LoanChargedOffSettledTile/LoanChargedOffSettledTile'
import {LoanHistoryTile} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile'
import TileListView, {
  type ReactNodeWithStyle,
} from 'src/products/loans/components/molecules/TileListView/TileListView'
import {
  LoanClosedReasonCode,
  LoanOfferAvailabilityStatus,
  LoanTypeVariant,
  SubscriptionStatus,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {isLoanClosedReasonCodePaidOff} from 'src/lib/loans/loansUtilsV2'
import {LoanPaidOff, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CashAdvanceEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {usePrevious} from 'src/lib/utils/hooks'
import {
  checkIfEligibleToApplyAgain,
  shouldShowLoanPaidOffTileForPaidOffLoan,
} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.utils'
import {DashboardLoanClosedProps} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.types'
import {usePromise} from 'src/lib/usePromise/usePromise'

import {InfoTile} from 'src/designSystem/components/molecules/InfoTile/InfoTile'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {LoanBrazeTiles} from 'src/products/loans/Dashboard/LoanBrazeTiles/LoanBrazeTiles'
import {useGetLinkedAccounts} from 'src/lib/bank/useGetLinkedAccounts'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {ReapplicationConfirmBankDocument} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBank.gqls'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {NextAdvanceTile} from 'src/products/advance/Dashboard/NextAdvanceTile/NextAdvanceTile'
import {
  LinkAccountTile,
  LinkAccountTileVariant,
} from 'src/designSystem/components/molecules/LinkAccountTile/LinkAccountTile'
import {useAccountManagementBankLinking} from 'src/products/MCU/AccountManagementV2/useAccountManagementBankLinking'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

/**
 * Dashboard displayed when a loan is closed and paid off.
 */

const DashboardLoanClosed: React.FC<DashboardLoanClosedProps> = (props) => {
  const {
    reason,
    reapplyOn,
    prequalAmount,
    payments,
    userEmailAddress,
    hasPreviousLoan,
    disbursement,
    onApplyForNewLoan,
    onShowDefaultExplanation: handleOnShowDefaultExplanation,
    onShowChargedOffExplanation: handleOnShowChargedOffExplanation,
    onContactUs: handleOnContactUs,
    onLoanRejectedLearnMore: handleOnLoanRejectedLearnMore,
    onViewStateDatabasePolicy: handleOnViewStateDatabasePolicy,
    onViewLoanHistory: handleOnViewLoanHistory,
    loanTypeVariant,
    subscriptions,
    loanOffers,
    isAdvance,
  } = props

  const {t} = useTranslation('DashboardLoanActive')
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const availableOffer = loanOffers.find(
    (offer) => offer.loanOfferAvailabilityStatus === LoanOfferAvailabilityStatus.OfferAvailable,
  )

  const hasStandardLoanOffer: boolean =
    availableOffer?.offers[0].loanTypeVariant !== LoanTypeVariant.NonRecourseInstallment

  const reapplyOnDateTime = new Date(reapplyOn)
  const previousCloseReason = usePrevious(reason)
  const {addOrRelinkAccountForPrimary} = useAccountManagementBankLinking()

  useEffect(() => {
    if (previousCloseReason !== reason) {
      if (isLoanClosedReasonCodePaidOff(reason)) {
        // track when a loan is paid off
        LoanPaidOff()
      }
      // the first time a close reason is viewed we track an analytics event for that particular close reason
      TrackAppEvent(
        ManageActiveLoanEvents.closed_dashboard_viewed,
        AppEvents.Category.ManageActiveLoan,
        {
          reason,
        },
      )
    }
  }, [previousCloseReason, reason])

  const {isAfterReapplyOnDate, shouldShowEligibleToApplyTile} = checkIfEligibleToApplyAgain({
    reason,
    reapplyOn,
    prequalAmount,
  })

  const {linkedAccountsData} = useGetLinkedAccounts()

  const [confirmBank] = useCassandraMutation(ReapplicationConfirmBankDocument)

  const preferredAccount = linkedAccountsData?.preferredAccount
  const latestAccount = linkedAccountsData?.latestAccount

  const tiles: (ReactNode | ReactNodeWithStyle)[] = []

  const [handleOnApply, {isLoading: isApplyLoading}] = usePromise(
    async (sourceForAnalytics: string | undefined): Promise<void> => {
      TrackAppEvent(
        AppEvents.Name.link_bank_account_current_account_selected,
        AppEvents.Category.Application,
      )

      if (isAdvance === true) {
        const accountId = preferredAccount?.id || latestAccount?.id

        if (!accountId) {
          throw new Error('No preferred or latest account found')
        }

        try {
          await confirmBank({variables: {accountId}})
          await onApplyForNewLoan({sourceForAnalytics})
        } catch (e) {
          logAddPaymentMethodError(e, 'Error while continuing with current account')
        }
      } else {
        await onApplyForNewLoan({sourceForAnalytics})
      }
    },
  )

  const onLinkAccount = (accountId?: string): void => {
    const accountToRelink = accountId ? {accountToRelink: {id: accountId}} : {}
    TrackAppEvent(
      AppEvents.Name.link_primary_bank_account_selected,
      AppEvents.Category.BankAccountManagement,
      {
        source: 'DashboardLoanClosed_LinkAccountTile',
      },
    )

    addOrRelinkAccountForPrimary({
      ...accountToRelink,
      onChoosePrimaryAccountComplete: (): Promise<void> => {
        TrackAppEvent(
          AppEvents.Name.link_primary_bank_account_successful,
          AppEvents.Category.BankAccountManagement,
          {
            source: 'DashboardLoanClosed_LinkAccountTile',
          },
        )
        navigation.navigate('Dashboard')
        return Promise.resolve()
      },
    })
  }

  if (
    isAdvance === true &&
    subscriptions?.current?.statusData.status === SubscriptionStatus.Cancelled
  ) {
    tiles.push(
      <InfoTile
        style={{marginHorizontal: 0}}
        headerProps={{
          title: t('CancelledMembershipTitle'),
          body: [t('CancelledMembershipBody')],
        }}
        primary={{
          text: t('CancelledMembershipButton'),
          onPress: async (): Promise<void> => {
            TrackAppEvent(
              CashAdvanceEvents.cash_resume_membership_selected,
              AppEvents.Category.CashAdvance,
            )
            await handleOnApply('DashboardLoanClosed-Membership-Cancelled-Info-Tile')
          },
        }}
      />,
    )
  }

  const isContentCardsEnabled = useIsFeatureFlagEnabled('braze-content-cards')

  const addContentCardTileIfAvailable = (): void => {
    if (isContentCardsEnabled) {
      tiles.push({
        element: <LoanBrazeTiles location={3} includeMarginTop />,
        style: {
          marginTop: 0,
        },
      })
    }
  }

  //Only show the LinkAccountTile for Advance users (for now) and if they don't have a preferred account (which means they revoked Plaid permissions)
  //OR if they need to reconnect their preferred account through Plaid
  if (isAdvance && (!preferredAccount || preferredAccount?.isLoginRequired === true)) {
    tiles.push(
      <LinkAccountTile
        testID="DashboardLoanClosed-LinkAccountTile"
        variant={LinkAccountTileVariant.Unlinked} //TODO: Add dynamic variant based on the reason after we have the cont-uw graphQL subscription
        isAdvance={isAdvance}
        handleOnLinkAccount={() => onLinkAccount(preferredAccount?.id)}
      />,
    )
  } else if (shouldShowEligibleToApplyTile) {
    // For Advance with prequalAmount > 0, show NextAdvanceTile
    if (isAdvance === true && prequalAmount && parseFloat(prequalAmount) > 0) {
      tiles.push(
        <NextAdvanceTile
          onApplyForNextAdvance={async (): Promise<void> => {
            TrackAppEvent(
              CashAdvanceEvents.cash_next_advance_selected,
              AppEvents.Category.CashAdvance,
            )
            await handleOnApply('DashboardLoanClosed-LoanPaidOffTile')
          }}
          testID="DashboardLoanClosed-NextAdvanceTile"
          amount={parseFloat(prequalAmount)}
        />,
      )
    } else {
      // For all other cases, use LoanEligibleToApplyTile with dynamic props
      const testID =
        isAdvance === true
          ? 'DashboardLoanClosed-Advance-EligibleToApplyTile'
          : 'DashboardLoanClosed-LoanEligibleToApplyTile'

      const sourceId =
        isAdvance === true
          ? 'DashboardLoanClosed-Advance-EligibleToApplyTile'
          : 'DashboardLoanClosed-LoanEligibleToApplyTile'

      const prequalifiedAmount =
        isAdvance === true ? undefined : prequalAmount ? parseFloat(prequalAmount) : undefined

      const handleOnApplyForNewLoan = async (): Promise<void> => {
        if (isAdvance === true) {
          TrackAppEvent(
            CashAdvanceEvents.cash_next_advance_selected,
            AppEvents.Category.CashAdvance,
          )
        }
        await handleOnApply(sourceId)
      }

      tiles.push(
        <LoanEligibleToApplyTile
          testID={testID}
          prequalifiedAmount={prequalifiedAmount}
          onApplyForNewLoan={handleOnApplyForNewLoan}
          isLoading={isApplyLoading}
          isAdvance={isAdvance}
          hasLoanOffer={hasStandardLoanOffer}
        />,
      )
    }
  }

  switch (reason) {
    case LoanClosedReasonCode.Paidoff:
    case LoanClosedReasonCode.ChargedoffPaidoff:
      // we won't show paid off loan users the LoanPaidOffTile if they had a prequal
      // since we want them to see the prequal offer in LoanEligibleToApplyTile instead
      if (
        shouldShowLoanPaidOffTileForPaidOffLoan({
          isAfterReapplyOnDate,
          prequalAmount,
        }) &&
        (isAdvance === false ||
          subscriptions?.current?.statusData.status !== SubscriptionStatus.Cancelled)
      ) {
        if (isAdvance === true) {
          if (preferredAccount && preferredAccount?.isLoginRequired === false) {
            tiles.push(
              <>
                <LoanEligibleToApplyTile
                  testID="DashboardLoanClosed-Advance-EligibleToApplyTile"
                  prequalifiedAmount={prequalAmount ? parseFloat(prequalAmount) : undefined}
                  onApplyForNewLoan={async (): Promise<void> => {
                    TrackAppEvent(
                      CashAdvanceEvents.cash_next_advance_selected,
                      AppEvents.Category.CashAdvance,
                    )
                    await handleOnApply('DashboardLoanClosed-Advance-EligibleToApplyTile')
                  }}
                  isLoading={isApplyLoading}
                  isAdvance={isAdvance}
                  hasLoanOffer={hasStandardLoanOffer}
                />
              </>,
            )
          }
        } else {
          tiles.push(
            <>
              <LoanPaidOffTile
                hasLoanOffer={hasStandardLoanOffer}
                isAdvance={isAdvance}
                isBusy={isApplyLoading}
                onApplyForNewLoan={async (): Promise<void> => {
                  await handleOnApply('DashboardLoanClosed-LoanPaidOffTile')
                }}
                reapplyOnDate={reapplyOnDateTime}
                testID="DashboardLoanClosed-LoanPaidOffTile"
              />
            </>,
          )
        }
      }

      addContentCardTileIfAvailable()

      tiles.push(
        <>
          <LoanProgressTile
            testID="DashboardLoanClosed-LoanProgressTile"
            payments={payments}
            fundingFailed={
              false /* not used when loan is closed since we only show this if its fully paid off */
            }
            amountProcessing="0.00" /* a closed loan will never have any amount processing / in progress */
            noAccountLinked={false /* same as fundingFailed */}
            onShowDefaultExplanation={handleOnShowDefaultExplanation}
            onShowChargedOffExplanation={handleOnShowChargedOffExplanation}
            onReschedule={
              /* when a loan is closed it cant be rescheduled so this isn't necessary */

              (): void => {}
            }
            onContactUs={handleOnContactUs}
            onRelinkBankAccount={
              /* when a loan is closed there is no UX to relink a bank account so this isn't necessary */

              (): void => {}
            }
            isAdvance={isAdvance}
          />
        </>,
      )
      // this should always exist on a paid off loan but per the type it may be undefined so we need a safety check to please TypeScript
      if (disbursement) {
        tiles.push(
          <>
            <LoanHistoryTile
              loanPayments={payments}
              hasPreviousLoan={hasPreviousLoan}
              onViewLoanHistory={handleOnViewLoanHistory}
              loanDisbursement={disbursement}
              testID="DashboardLoanClosed-LoanHistoryTile"
              loanTypeVariant={loanTypeVariant}
              subscriptions={subscriptions}
              isAdvance={isAdvance === true}
            />
          </>,
        )
      }
      break
    case LoanClosedReasonCode.Rejected:
      if (isAfterReapplyOnDate) {
        // if it's after the reapplyOn date they should see the LoanEligibleToApplyTile
        // to let them apply for a new loan
        addContentCardTileIfAvailable()
        break
      }
      tiles.push(
        <>
          <LoanRejectedTile
            testID="DashboardLoanClosed-LoanRejectedTile"
            onLoanRejectedLearnMore={handleOnLoanRejectedLearnMore}
            userEmailAddress={userEmailAddress}
            reapplyOnDate={reapplyOnDateTime}
            isAdvance={isAdvance === true}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.LoanExpired:
    case LoanClosedReasonCode.DecisioningExpired:
      tiles.push(
        <>
          <LoanExpiredTile
            wasApproved={reason === LoanClosedReasonCode.LoanExpired}
            testID="DashboardLoanClosed-LoanExpiredTile"
            onApplyForNewLoan={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanExpiredTile')
            }}
            isBusy={isApplyLoading}
            isAdvance={isAdvance === true}
            hasLoanOffer={hasStandardLoanOffer}
          />
        </>,
      )
      addContentCardTileIfAvailable()
      break
    case LoanClosedReasonCode.UnmetStateRequirement:
      if (isAfterReapplyOnDate) {
        break
      }
      tiles.push(
        <>
          <LoanUnmetStateRequirementsTile
            testID="DashboardLoanClosed-LoanUnmetStateRequirementsTile"
            onApplyAgain={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanUnmetStateRequirementsTile')
            }}
            onViewStateDatabasePolicy={handleOnViewStateDatabasePolicy}
            isBusy={isApplyLoading}
            hasLoanOffer={hasStandardLoanOffer}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.Cancelled:
      tiles.push(
        <>
          <LoanCancelledTile
            testID="DashboardLoanClosed-LoanCancelledTile"
            onApplyForNewLoan={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanCancelledTile')
            }}
            isBusy={isApplyLoading}
            hasLoanOffer={hasStandardLoanOffer}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.ChargedoffSettled:
      tiles.push(
        <LoanChargedOffSettledTile
          hasLoanOffer={hasStandardLoanOffer}
          isBusy={isApplyLoading}
          onApplyForNewLoan={async (): Promise<void> => {
            await handleOnApply('DashboardLoanClosed-LoanChargedOffSettledTile')
          }}
          reapplyOnDate={reapplyOnDateTime}
          testID="DashboardLoanClosed-LoanChargedOffSettledTile"
        />,
      )
      addContentCardTileIfAvailable()
      break
    case LoanClosedReasonCode.Other:
      throw new Error('"Other" close reason not implemented')
  }
  return <TileListView testID="DashboardLoanClosed" list={tiles} />
}

export {DashboardLoanClosed}
