import React, {FC} from 'react'

import {AppEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {EmailConfirmation} from 'src/products/general/EmailConfirmation/EmailConfirmationGQLContainer'
import {trackSubmitCode} from 'src/products/loans/EmailVerification/EmailVerification.utils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export const EmailVerificationWorkflowContainer: FC<WorkflowPreReqFulfillScreenProps> = ({
  onPreReqFulfilled,
}) => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_email_verification_code_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  const handleOnCodeSubmitted = async (): Promise<void> => {
    trackSubmitCode()
    await onPreReqFulfilled()
  }

  return <EmailConfirmation onCodeSubmitted={handleOnCodeSubmitted} />
}
