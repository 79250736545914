import {ApolloQueryResult} from '@apollo/client'
import {StackNavigationProp} from '@react-navigation/stack'
import {PlatformOSType} from 'react-native'

import {Consumer} from '@possible/cassandra'
import {LoanActivationPreRequisiteType} from '@possible/cassandra/src/types/types.mobile.generated'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {BankAggregatorAccountSelectionGQLContainerProps} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelection.types'

/**
 * @summary
 * This section is comprised of types that will need to be edited
 * and maintained to make any significant user facing changes to workflows.
 */

/**
 * The screen params for the screens that make up the signup workflow.
 */
export type SignupWorkflowStackParams = {
  Loading: undefined
  PhoneConfirmation: {
    optedOutOfPlaidLayer?: boolean
  }
  StateSelect: undefined
  LoanAmountSelection: undefined
  PhoneVerification: undefined
  PersonalInformation: undefined
  AddressHome: undefined
  ApplyInOneClick: undefined
  NotificationsPermissions: undefined
  ApplicationSteps: undefined
}

/**
 * The screen params for the screens that make up the offer application workflow.
 */
export type OfferApplicationWorkflowStackParams = {
  Loading: undefined
  PIIConfirm: undefined
  SSN: undefined
  BankLinking: undefined
  EmailVerification: undefined
  MarketingSurvey: undefined
  PrimaryAccountSelection:
    | {
        isForReviewAndEdit?: boolean
      }
    | undefined

  ReapplicationBankConfirmation: undefined
  LoanProductInformation: undefined
  LoanAmountSelection: undefined
  LoanApplicationSubmission: undefined

  CardApplicationFinances: undefined
  CardApplicationSubmission: undefined

  /** Banking Screens */
  AggregatorPlaid: undefined
  BankAggregatorAccountSelectionForPrimaryAccount: Pick<
    BankAggregatorAccountSelectionGQLContainerProps,
    | 'accountsFromBankAggregator'
    | 'onVerifyRoutingAndAccountFailed'
    | 'onVerifyRoutingAndAccountSucceeded'
  >
  AcceptPrimaryAccountAgreement: {
    accountMask: string
    onAccept: () => Promise<void>
    linkedAccountIdForPrimary: string
  }
}

/**
 * The screen params for the screens that make up the application activation workflow.
 */
export type ApplicationActivationWorkflowStackParams = {
  Loading: undefined
  PostApplicationActivationError: {
    error: Error
  }

  // loans stuff
  /**
   * PaymentReview is for the following prereq(s):
   *  ACCEPT_PAYMENTS
   */
  PaymentReview: undefined
  /**
   * DisbursementMethodSelection is for the following prereq(s):
   *  SELECT_DISBURSEMENT_METHOD
   */
  DisbursementMethodSelection: undefined
  /**
   * AcceptAutoPayAgreementStandard is for the following prereq(s):
   *  ACCEPT_AUTOPAY_AGREEMENT_STANDARD
   *
   * NOTE: The screen and UX we show for this differs depending on the user's scenario. For example, usings
   * in TX will accept autopay on a different screen
   */
  AcceptAutoPayAgreement: undefined
  /**
   * AcceptAutoPayAgreementStandard is for the following prereq(s):
   *  ACCEPT_LOAN_AGREEMENT
   * NOTE: There is no screen for ACCEPT_LOAN_AGREEMENT on its own. This route will determine which
   * screen to navigate to based on the user's scenario.
   */
  AcceptLoanAgreement: undefined
  /**
   * AcceptAutoPayAgreementExtendedWA is for the following prereq(s):
   *  ACCEPT_AUTOPAY_AGREEMENT_EXTENDED
   */
  AcceptAutoPayAgreementExtendedInstallmentPlan: undefined
  /**
   * AcceptLoanAndStateAgreementsTX is for the following prereq(s)
   *  ACCEPT_CREDIT_SERVICES_AGREEMENT_TX,
   *  ACCEPT_CREDIT_SERVICES_DISCLOSURE_STATEMENT_TX,
   *  ACCEPT_ARBITRATION_AGREEMENT_TX
   *  ACCEPT_LOAN_AGREEMENT
   */
  AcceptStateAgreementsTX: undefined
  /**
   * AcceptLoanAndStateAgreementsFL is for the following prereq(s):
   *  ACCEPT_ARBITRATION_AGREEMENT_FL,
   *  ACCEPT_STATUTORY_NOTICE_FL,
   *  ACCEPT_LOAN_AGREEMENT
   */
  AcceptStateAgreementsFL: undefined
  /**
   * AcceptLoanAndStateAgreementsHI is for the following prereq(s):
   *  ACCEPT_INSTALLMENT_PLAN_DISCLOSURE_HI,
   *  ACCEPT_LOAN_AGREEMENT
   */
  AcceptStateAgreementsHI: undefined
  /**
   * HowAutoPayWorks is for the following prereq(s):
   *  ACCEPT_HOW_AUTOPAY_WORKS,
   */
  HowAutoPayWorks: undefined
  /**
   * StateDisclosure is for the following prereq(s):
   *  ACCEPT_STATE_DISCLOSURE_OH
   */
  StateDisclosureOH: undefined
  /**
   * StateDisclosure is for the following prereq(s):
   *  ACCEPT_STATE_DISCLOSURE_LA,
   */
  StateDisclosureLA: undefined
  /**
   * TilaDisclosure is for the following prereq(s):
   *  ACCEPT_TILA_DISCLOSURE,
   */
  TilaDisclosure: undefined
  /**
   * ConfirmDebitCard is for the following prereq(s):
   *  CONFIRM_DEBIT_CARD,
   */
  ConfirmDebitCard: undefined
  /**
   * CollectDebitCardNumbers is for the following prereq(s):
   *  COLLECT_DEBIT_CARD_NUMBERS,
   */
  CollectDebitCardNumbers: undefined
  /**
   * ConfirmPrimaryBankAccountDetails is for the following prereq(s):
   *  CONFIRM_PREFERRED_BANK_ACCOUNT_DETAILS,
   */
  ConfirmPrimaryBankAccountDetails: undefined
  /**
   * RelinkPreferredBankAccount is for the following prereq(s):
   *  RELINK_PREFERRED_BANK_ACCOUNT,
   */
  RelinkPreferredBankAccount: undefined
  /**
   * ReasonSurvey is for the following prereq(s):
   *  COMPLETE_LOAN_REASON_SURVEY,
   */
  ReasonSurvey: undefined
  /**
   * FinalAcceptance is for the following prereq(s):
   *  FINAL_ACCEPT_STANDARD,
   */
  FinalAcceptance: undefined
  /**
   * FinalAcceptanceForExtendedInstallmentPlanWA is for the following prereq(s):
   *  No prereq exists yet
   */
  FinalAcceptanceForExtendedInstallmentPlanWA: undefined

  // card stuff
  CardApproved: undefined
  CardAutopayOverview: undefined
  // CardReviewAutopayTemplate is essentially a clone of CardActivationAutopayScheduleTemplate but lacks the opt-out of autopay button. Eventually we want to remove CardReviewAutopayTemplate and only use CardActivationAutopaySchedule, but for now we'll use a route param to control.
  CardActivationAutopaySchedule: {showOptOutOfAutoPay?: boolean}
  CardAgreements: undefined
  CardCreditBureauFlowBankLinking: undefined
  CardCreditBureauFlowPrimaryAccountSelection: undefined

  // Manual pay flow
  CardManualPayOverview: undefined
  CardManualPaySchedule: undefined

  CardActivated: undefined

  LoanReapplicationSubmission: undefined

  /**
   * AcceptSubscriptionAgreement is for the following prereq(s):
   *  ACCEPT_SUBSCRIPTION,
   */
  AcceptSubscription: undefined
}

/**
 * Pre-reqs that are only fulfilled and marked as so (via the user misc prop) on the frontend.
 * Edit this to add or remove pre-reqs that the backend does not track.
 */
export const FrontEndProductPreRequisites = ['PII_CONFIRM'] as const

/**
 * Type guard to provide type safety when checking if a prereq is a FrontEndPreReq.
 */
export const isFrontEndPreReq = (value: string): value is FrontEndPreReqType => {
  for (const thisFrontEndPreReq of FrontEndProductPreRequisites) {
    if (value === thisFrontEndPreReq) {
      return true
    }
  }
  return false
}

/**
 * @summary
 * This section are types that are used to build workflows and are not
 * meant to be frequently edited
 */

/**
 * The types returned by the `useSelectedOffer` hook.
 */
export type SelectedOfferLoading = {
  status: 'LOADING'
}
export type SelectedOfferNone = {
  status: 'NONE'
  offerId: null
  offer: null
  unmetPreReqs: null
  metPreReqs: null
}
export type SelectedOfferInformation = {
  status: 'SELECTED'
  offerId: string
  /** The offer as returned by the backend */
  offer: ProductsQueryOfferType
  /** ALL unmet pre-reqs (backend and frontend) */
  unmetPreReqs: PreReqType[]
  /** Frontend ONLY unmet pre-reqs */
  unmetFrontEndPreReqs: FrontEndPreReqType[]
  /** ALL met pre-reqs (backend and frontend) */
  metPreReqs: PreReqType[]
}
export type SelectedOfferType = SelectedOfferLoading | SelectedOfferNone | SelectedOfferInformation

/**
 * The types returned by the `useWorkflowsInitialRoute` hook.
 */
export type WorkflowsInitialRouteLoading = {
  loading: true
}
export type WorkflowsInitialRouteResolved = {
  loading: false
  error: undefined
  name: keyof MainStackParamList
}
export type WorkflowsInitialRouteError = {
  loading: false
  error: Error
  refetch: () => Promise<ApolloQueryResult<Consumer.types.ProductsQuery>>
}
export type WorkflowsInitialRouteType =
  | WorkflowsInitialRouteLoading
  | WorkflowsInitialRouteResolved
  | WorkflowsInitialRouteError

/**
 * The types returned by the `useWorkflows` hook.
 */
export type WorkflowsErrorType = {
  status: 'ERROR'
  error: Error
  refetch: () => Promise<ApolloQueryResult<Consumer.types.ProductsQuery>>
}
export type WorkflowsLoadingType = {
  status: 'LOADING'
  loading: true
}
export type WorkflowsReadyType = {
  status: 'READY'
  loading: boolean
  applications: ProductsQueryApplicationsType[]
  unmetMinPreReqs: Consumer.types.ProductPreRequisiteType[]
  metMinPreReqs: Consumer.types.ProductPreRequisiteType[]
  offers: ProductsQueryAllEligibleOffersType
  ineligibleOffers: ProductsQueryAllIneligibleOffersType
  selectedOffer: SelectedOfferInformation | null
  refetch: () => Promise<ApolloQueryResult<Consumer.types.ProductsQuery>>
}
export type WorkflowsType = WorkflowsErrorType | WorkflowsLoadingType | WorkflowsReadyType

/**
 * Props passed to a screen that are used to fulfill a prereq or move to the next screen.
 */
export type WorkflowPreReqFulfillScreenProps = {
  onPreReqFulfilled: () => Promise<void>
}
export type WorkflowScreenProps = {
  onScreenComplete: () => void
}
export type WorkflowsPreReqFullfilledFunction<S extends keyof WorkflowsStackParams> = <
  Params extends WorkflowsStackParams[S],
>(
  params: Params,
) => Promise<void>

/**
 * The buttons that can be displayed in the header of a workflow screen.
 */
export type WorkflowHeaderButton = 'Logout' | 'Back' | 'Menu' | 'None'

/**
 * Workflows state saved as a user misc prop on the BE.
 */
export type WorkflowsUserMiscPropPayload = {
  metOneTimeFrontEndPreReqs: Array<FrontEndPreReqType>
  selectedOffer?: {
    offerId: string
    metFrontEndPreReqs: Array<FrontEndPreReqType>
  }
}

/**
 * The state of the workflows slice.
 */
export type WorkflowsState = {
  fetched: boolean
} & WorkflowsUserMiscPropPayload

/**
 * The combined screen params for all the workflows.
 */
export type WorkflowsStackParams = SignupWorkflowStackParams &
  OfferApplicationWorkflowStackParams &
  ApplicationActivationWorkflowStackParams

/**
 * All of the screen names for the combined workflows.
 */
export type WorkflowsPages = keyof WorkflowsStackParams

/**
 * A unionized version of the BE pre reqs enum type.
 */
export type BackEndPreReqType =
  | `${Consumer.types.ProductPreRequisiteType}`
  | `${LoanActivationPreRequisiteType}`

/**
 * Type guard to provide type safety when checking if a prereq is a BackEndPreReqType.
 */
export const isBackEndPreReq = (value: string): value is BackEndPreReqType => {
  const backEndPreReqValues = [
    ...Object.values(Consumer.types.ProductPreRequisiteType),
    ...Object.values(LoanActivationPreRequisiteType),
  ]
  for (const thisBackEndPrereq of backEndPreReqValues) {
    if (value === thisBackEndPrereq.valueOf()) {
      return true
    }
  }
  return false
}

/**
 * A unionized version of the frontend pre reqs enum type.
 */
export type FrontEndPreReqType = (typeof FrontEndProductPreRequisites)[number]

/**
 * A string union of ALL pre-reqs that can be fulfilled.
 */
export type PreReqType =
  | BackEndPreReqType
  | FrontEndPreReqType
  | `${LoanActivationPreRequisiteType}`

/**
 * These are all just breaking down the query response type into
 * the individual types we will need to reference.
 */
export type ProductsQueryProductsType = NonNullable<
  ReturnType<typeof Consumer.hooks.useProductsQuery>['data']
>['me']['products']
export type ProductsQueryApplicationsType = ProductsQueryProductsType['applications']['all'][number]
export type ProductsQueryEligibleOffersType = ProductsQueryProductsType['eligible']
export type ProductsQueryAllEligibleOffersType = ProductsQueryEligibleOffersType['all']
export type ProductsQueryAllIneligibleOffersType = ProductsQueryProductsType['ineligible']['all']
export type ProductsQueryOfferType = ProductsQueryAllEligibleOffersType[number]['offers'][number]
export type ProductsQueryOfferTypenameType = ProductsQueryOfferType['__typename']
export type ProductsQueryEligibleLoanOfferInfo = ProductsQueryAllEligibleOffersType[number] & {
  __typename: 'LoanOfferInfo'
}

type WorkflowBasePreReqDescriptor = {
  /**
   * The route name that fulfills the pre-req.
   */
  screen: keyof WorkflowsStackParams

  /**
   * The button that should be displayed in the header of the screen.
   */
  button?: {left: WorkflowHeaderButton; right?: WorkflowHeaderButton}

  /**
   * If set to true the screen will NOT be added to the navigation stack
   * when the navigation gets reset (i.e. when the app is closed and reopened)
   * AND
   * the screen will NOT be displayed again when moving forward after
   * going back beyond this screen.
   */
  shouldExcludeFromNavigationHistory?: boolean

  /**
   * Which platforms, if any, for which this pre-req should instead redirect to web.
   * Right now only Android should be redirecting to web in some instances, but hey,
   * adding support for other platforms just because.
   */
  redirectToWeb?: {
    platforms: PlatformOSType[]
    associatedOfferTypenames: ProductsQueryOfferTypenameType[]
  }
}

export type WorkflowBackEndPreReqDescriptor = WorkflowBasePreReqDescriptor & {
  isFrontEndPreReq?: false
}

export type WorkflowFrontEndPreReqDescriptor = WorkflowBasePreReqDescriptor & {
  isFrontEndPreReq: true

  /**
   * Optional. If filled this pre-req will be fulfilled
   * by any screen in the list and not just the `screen`
   * specified in `WorkflowBasePreReqDescriptor`.
   */
  fulfilledByScreens?: (keyof WorkflowsStackParams)[]

  /**
   * For front end pre-reqs we need to know for which offer types
   * it must be fulfilled.
   */
  associatedOfferTypenames: ProductsQueryOfferTypenameType[]

  /**
   * If set to true, once fulfilled, the screen associated with the
   * pre-req will never be shown again.
   */
  needsToBeFulfilledJustOnce?: boolean
}

export type WorkflowDescriptorType = Partial<{
  [k in PreReqType]: k extends BackEndPreReqType
    ? WorkflowBackEndPreReqDescriptor
    : WorkflowFrontEndPreReqDescriptor
}>

export type MoveToNextPreReqRouteArgsType<
  T extends ApolloQueryResult<unknown> = ApolloQueryResult<Consumer.types.ProductsQuery>,
> = {
  navigation: StackNavigationProp<
    MainStackParamList,
    'SignupWorkflow' | 'OfferApplicationWorkflow' | 'ApplicationActivationWorkflow'
  >
  refetch: () => Promise<T>
  preReqSelector: (response: T) => {met: PreReqType[]; unmet: PreReqType[]}
  selectedOffer?: SelectedOfferInformation
  workflowName: string

  /**
   * Optional. Pass a callback to be notified when all pre-reqs are met.
   * If the callback returns false, the workflows default behavior will be invoked.
   * If the callback returns true, the workflows default behavior will be skipped.
   * The default behavior of workflows is to reset navigation to ProductHub
   * @returns True if the default behavior should be skipped, false otherwise.
   */
  onAllPreReqsMet?: () => boolean | Promise<boolean>
  previousPreReqs?: {met: PreReqType[]; unmet: PreReqType[]}
}
