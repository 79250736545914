/* eslint-disable */
import {parseGeneratedTypes} from '@possible/cassandra/src/types/allDocuments'

/**
 * This file is automatically generated by the script at packages/mobile/tools/generate-gql-colocated-operations-index/generate-script.ts.
 * This file contains all the co-located GraphQL operation documents in packages/mobile/ exported from a single index.
 */

/* AUTO-GENERATED IMPORTS BEGIN */
import * as UserProperties from 'src/api/lib/UserProperties/UserProperties.gqls'
import * as useGetAdvanceCountQuery from 'src/lib/advance/useGetAdvanceCount/useGetAdvanceCountQuery.gqls'
import * as useIsAdvanceQuery from 'src/lib/advance/useIsAdvance/useIsAdvanceQuery.gqls'
import * as AccountList from 'src/lib/bank/queries/AccountList.gqls'
import * as UseLoanSubmission from 'src/lib/loans/useLoanSubmission/UseLoanSubmission.gqls'
import * as UserEnv from 'src/lib/user/queries/UserEnv.gqls'
import * as UseUra from 'src/nav/UseUra/UseUra.gqls'
import * as CardActivatedQuery from 'src/products/card/Activation/CardActivated/CardActivatedGQLContainer/CardActivatedQuery.gqls'
import * as CardActivationAutopayScheduleMutation from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleGQLContainer/CardActivationAutopayScheduleMutation.gqls'
import * as CardActivationAutopayScheduleQuery from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleGQLContainer/CardActivationAutopayScheduleQuery.gqls'
import * as CardManualPaySchedule from 'src/products/card/Activation/CardManualPaySchedule/CardManualPayScheduleGQLContainer/CardManualPaySchedule.gqls'
import * as CardPaymentAgreementQuery from 'src/products/card/Activation/CardPaymentAgreement/CardPaymentAgreementGQLContainer/CardPaymentAgreementQuery.gqls'
import * as useCardAdhocPayment from 'src/products/card/AdhocPayment/useCardAdhocPayment/useCardAdhocPayment.gqls'
import * as CardSetUserSelfReportedData from 'src/products/card/Application/CardApplicationFinances/CardSetUserSelfReportedData.gqls'
import * as CohortUserTypeQuery from 'src/products/card/Application/useGetCohortUserType/CohortUserTypeQuery.gqls'
import * as EligibleToApplyForCardQuery from 'src/products/card/Application/UseIsCardEligible/EligibleToApplyForCardQuery.gqls'
import * as PaymentFrequencyQuery from 'src/products/card/Autopay/UseGetPaymentFrequency/PaymentFrequencyQuery.gqls'
import * as CardAccountPanDecode from 'src/products/card/CardDetails/CardAccountPanDecode.gqls'
import * as CardDocumentsQuery from 'src/products/card/CardsHistory/UseCardDocuments/CardDocumentsQuery.gqls'
import * as CardAccountIdQuery from 'src/products/card/Common/CardAccountIdQuery.gqls'
import * as CardMinPayments from 'src/products/card/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import * as CardRescheduleUpcomingPayment from 'src/products/card/Dashboard/CardRescheduleUpcomingPayment/CardRescheduleUpcomingPayment.gqls'
import * as CardLoanDashboardTileQuery from 'src/products/card/LoanDash/UseCardLoanDashboardTile/CardLoanDashboardTileQuery.gqls'
import * as CardVerifyBankDetails from 'src/products/card/PaymentMethods/CardVerifyBankDetails.gqls'
import * as UseDefaultPaymentAccount from 'src/products/card/PaymentMethods/UseDefaultPaymentAccount/UseDefaultPaymentAccount.gqls'
import * as useEnablePayOverTimeQuery from 'src/products/card/PaymentMethods/useEnablePayOverTimeQuery.gqls'
import * as CardHowPayOverTimeWorksManualQuery from 'src/products/card/PayOverTime/CardHowPayOverTimeWorksManualQuery.gqls'
import * as CardPayOverTimeDetailsMutation from 'src/products/card/PayOverTime/CardPayOverTimeDetails/CardPayOverTimeDetailsMutation.gqls'
import * as CardPayOverTimeDetailsQuery from 'src/products/card/PayOverTime/CardPayOverTimeDetails/CardPayOverTimeDetailsQuery.gqls'
import * as CardPayOverTimeScheduleQuery from 'src/products/card/PayOverTime/CardPayOverTimeSchedule/CardPayOverTimeScheduleQuery.gqls'
import * as AddBankLinkWorkflowContainer from 'src/products/general/AddBankLink/AddBankLinkWorkflowContainer.gqls'
import * as BankAccounts from 'src/products/general/AddBankLink/queries/BankAccounts.gqls'
import * as AddressUpdates from 'src/products/general/AddressUpdates/AddressUpdates.gqls'
import * as ApprovedApplications from 'src/products/general/ApplicationActivationWorkflow/ApprovedApplications.gqls'
import * as LoanApplicationActivationWorkflow from 'src/products/general/ApplicationActivationWorkflow/LoanApplicationActivationWorkflow/LoanApplicationActivationWorkflow.gqls'
import * as AccountStatusQuery from 'src/products/general/ApplicationProcessing/AccountStatusQuery.gqls'
import * as LoanApplicationProcessingStatus from 'src/products/general/ApplicationProcessing/LoanApplicationProcessingStatus.gqls'
import * as getUserEmail from 'src/products/general/EmailConfirmation/queries/getUserEmail.gqls'
import * as AccountSelectionAddAchPaymentMethodForLinkedAccount from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/mutations/AccountSelectionAddAchPaymentMethodForLinkedAccount/AccountSelectionAddAchPaymentMethodForLinkedAccount.gqls'
import * as BankAddAchPaymentMethod from 'src/products/general/GeneralPaymentMethods/BankRoutingAndAccountFormPage/mutations/BankAddAchPaymentMethod/BankAddAchPaymentMethod.gqls'
import * as useLinkAchPaymentMethodMutation from 'src/products/general/GeneralPaymentMethods/useLinkAchPaymentMethod/useLinkAchPaymentMethodMutation.gqls'
import * as MarketingSurvey from 'src/products/general/MarketingSurvey/MarketingSurvey.gqls'
import * as PersonalInfoConfirmation from 'src/products/general/PersonalInfoConfirmation/PersonalInfoConfirmation.gqls'
import * as ProductHub from 'src/products/general/ProductHub/ProductHub.gqls'
import * as UserSetResidenceState from 'src/products/general/StateSelect/queries/UserSetResidenceState.gqls'
import * as AchAccountNumber from 'src/products/general/UserRequestedActions/AchAccountNumber/AchAccountNumber.gqls'
import * as UserGenerateZendeskToken from 'src/products/general/ZendeskHelpCenter/queries/UserGenerateZendeskToken.gqls'
import * as DashboardLoanAggregateStatusQuery from 'src/products/loans/Dashboard/DashboardLoan/queries/DashboardLoanAggregateStatusQuery.gqls'
import * as DashboardLoan from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils.gqls'
import * as EmailVerification from 'src/products/loans/EmailVerification/EmailVerification.gqls'
import * as GetCardCohortPhase from 'src/products/loans/Landing/GetCardCohortPhase.gqls'
import * as LoanAmountSelection from 'src/products/loans/LoanAmountSelection/LoanAmountSelection.gqls'
import * as LoanSubmission from 'src/products/loans/LoanApplicationSubmission/operations/LoanSubmission.gqls'
import * as AcceptAgreements from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsGQLContainer/AcceptAgreements.gqls'
import * as AcceptLoanAndAutoPayAgreements from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreements.gqls'
import * as AcceptLoanAndStateAgreements from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreements.gqls'
import * as useGetAgreementDocument from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useGetAgreementDocument/useGetAgreementDocument.gqls'
import * as useSaveAcceptAgreementsResult from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useSaveAcceptAgreementsResult/useSaveAcceptAgreementsResult.gqls'
import * as advanceQueries from 'src/products/loans/LoanApprovedActivation/advanceUtils/advanceQueries.gqls'
import * as CancelSurvey from 'src/products/loans/LoanApprovedActivation/CancelSurvey/CancelSurvey.gqls'
import * as ConfirmBankAccount from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccount.gqls'
import * as ConfirmDebitCard from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCard.gqls'
import * as HowPaymentsWork from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkGQLContainer/HowPaymentsWork.gqls'
import * as LoanDisbursementMethodSelection from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelection.gqls'
import * as LoanFinalAccept from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAccept.gqls'
import * as LoanPaymentReview from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReview.gqls'
import * as LoanTilaDisclosure from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosure.gqls'
import * as PaymentReviewTilaAndLoanAgreementCA from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCA.gqls'
import * as RelinkPreferredBankAccount from 'src/products/loans/LoanApprovedActivation/RelinkPreferredBankAccount/RelinkPreferredBankAccount.gqls'
import * as StateDisclosure from 'src/products/loans/LoanApprovedActivation/StateDisclosure/StateDisclosure.gqls'
import * as useAcceptLoan from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan.gqls'
import * as LoanHistory from 'src/products/loans/LoanHistory/LoanHistory.gqls'
import * as LoanProcessingQuery from 'src/products/loans/LoanProcessing/LoanProcessingQuery.gqls'
import * as LoanAmount from 'src/products/loans/LoanProcessing/queries/LoanAmount.gqls'
import * as LoanPayAmountEntry from 'src/products/loans/MakePayment/LoanPayAmountEntry/LoanPayAmountEntry.gqls'
import * as LoanPayConfirm from 'src/products/loans/MakePayment/LoanPayConfirm/LoanPayConfirm.gqls'
import * as LoanPayMethods from 'src/products/loans/MakePayment/LoanPayMethods/LoanPayMethods.gqls'
import * as NetworkFailureError from 'src/products/loans/NetworkFailureError/NetworkFailureError.gqls'
import * as ProofOfResidence from 'src/products/loans/PersonalInformation/ProofOfResidence/ProofOfResidence.gqls'
import * as LoanCount from 'src/products/loans/ProductChoice/LoanCount.gqls'
import * as ReschedulePayment from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePayment.gqls'
import * as SelectPaymentToReschedule from 'src/products/loans/Reschedule/SelectPaymentToReschedule/SelectPaymentToReschedule.gqls'
import * as AddressQuery from 'src/products/loans/StateSelector/AddressQuery.gqls'
import * as CardOfferAvailabilityStatusQuery from 'src/products/loans/StateSelector/UseGetWaitlistVariant/CardOfferAvailabilityStatusQuery.gqls'
import * as UpgradeToInstallmentPlan from 'src/products/loans/UpgradeToInstallmentPlan/UpgradeToInstallmentPlan.gqls'
import * as AcceptLoanAutoPayAgreement from 'src/products/MCU/AccountManagementV2/AcceptLoanAutoPayAgreement/AcceptLoanAutoPayAgreement.gqls'
import * as AcceptPrimaryAccountAgreement from 'src/products/MCU/AccountManagementV2/AcceptPrimaryAccountAgreement/AcceptPrimaryAccountAgreement.gqls'
import * as AddNewAccountToPossible from 'src/products/MCU/AccountManagementV2/AddNewAccount/AddNewAccountToPossible.gqls'
import * as AggregatorAccountSelectionForPrimaryAllAccounts from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorAccountSelectionForPrimaryAccount/queries/AggregatorAccountSelectionForPrimaryAllAccounts/AggregatorAccountSelectionForPrimaryAllAccounts.gqls'
import * as BankCreateLinkedAccounts from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/mutations/BankCreateLinkedAccounts.gqls'
import * as ReapplicationBank from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBank.gqls'
import * as useLinkDebitPaymentMethod from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForCardProduct/useLinkDebitPaymentMethod.gqls'
import * as CollectDebitCardNumbersForLoan from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoan.gqls'
import * as BankAddDebitPaymentMethod from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/mutations/BankAddDebitPaymentMethod/BankAddDebitPaymentMethod.gqls'
import * as LatestLoanStatus from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/queries/LatestLoanStatus.gqls'
import * as useSetPrimaryAccount from 'src/products/MCU/AccountManagementV2/PrimaryAccount/useSetPrimaryAccount/useSetPrimaryAccount.gqls'
import * as PrimaryAccountSelectionGQLContainer from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionGQLContainer.gqls'
import * as AccountManagementAccounts from 'src/products/MCU/AccountManagementV2/queries/AccountManagementAccounts/AccountManagementAccounts.gqls'
import * as SetLoanAutoPayAccount from 'src/products/MCU/AccountManagementV2/SetLoanAutoPayAccount/SetLoanAutoPayAccount.gqls'
import * as AddressCollection from 'src/products/MCU/Address/queries/AddressCollection.gqls'
import * as UpdateAddressGetOffers from 'src/products/MCU/Address/queries/UpdateAddressGetOffers.gqls'
import * as PhoneNumberInputVerification from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerification.gqls'
import * as CheckIsPlaidLayerAvailable from 'src/products/MCU/PhoneNumberInputVerification/queries/CheckIsPlaidLayerAvailable.gqls'
import * as ProcessPlaidProfile from 'src/products/MCU/PlaidLayers/mutations/ProcessPlaidProfile.gqls'
import * as GetPlaidLinkTokenForLayers from 'src/products/MCU/PlaidLayers/queries/GetPlaidLinkTokenForLayers.gqls'
import * as Profile from 'src/products/MCU/Profile/Profile.gqls'
import * as PersonalInformation from 'src/products/MCU/VerifyYourIdentity/operations/PersonalInformation.gqls'
import * as VerifyYourIdentity from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.gqls'
import * as workflowInit from 'src/workflows/hooks/workflowInit.gqls'
const genTypes = {
  ...UserProperties,
  ...useGetAdvanceCountQuery,
  ...useIsAdvanceQuery,
  ...AccountList,
  ...UseLoanSubmission,
  ...UserEnv,
  ...UseUra,
  ...CardActivatedQuery,
  ...CardActivationAutopayScheduleMutation,
  ...CardActivationAutopayScheduleQuery,
  ...CardManualPaySchedule,
  ...CardPaymentAgreementQuery,
  ...useCardAdhocPayment,
  ...CardSetUserSelfReportedData,
  ...CohortUserTypeQuery,
  ...EligibleToApplyForCardQuery,
  ...PaymentFrequencyQuery,
  ...CardAccountPanDecode,
  ...CardDocumentsQuery,
  ...CardAccountIdQuery,
  ...CardMinPayments,
  ...CardRescheduleUpcomingPayment,
  ...CardLoanDashboardTileQuery,
  ...CardVerifyBankDetails,
  ...UseDefaultPaymentAccount,
  ...useEnablePayOverTimeQuery,
  ...CardHowPayOverTimeWorksManualQuery,
  ...CardPayOverTimeDetailsMutation,
  ...CardPayOverTimeDetailsQuery,
  ...CardPayOverTimeScheduleQuery,
  ...AddBankLinkWorkflowContainer,
  ...BankAccounts,
  ...AddressUpdates,
  ...ApprovedApplications,
  ...LoanApplicationActivationWorkflow,
  ...AccountStatusQuery,
  ...LoanApplicationProcessingStatus,
  ...getUserEmail,
  ...AccountSelectionAddAchPaymentMethodForLinkedAccount,
  ...BankAddAchPaymentMethod,
  ...useLinkAchPaymentMethodMutation,
  ...MarketingSurvey,
  ...PersonalInfoConfirmation,
  ...ProductHub,
  ...UserSetResidenceState,
  ...AchAccountNumber,
  ...UserGenerateZendeskToken,
  ...DashboardLoanAggregateStatusQuery,
  ...DashboardLoan,
  ...EmailVerification,
  ...GetCardCohortPhase,
  ...LoanAmountSelection,
  ...LoanSubmission,
  ...AcceptAgreements,
  ...AcceptLoanAndAutoPayAgreements,
  ...AcceptLoanAndStateAgreements,
  ...useGetAgreementDocument,
  ...useSaveAcceptAgreementsResult,
  ...advanceQueries,
  ...CancelSurvey,
  ...ConfirmBankAccount,
  ...ConfirmDebitCard,
  ...HowPaymentsWork,
  ...LoanDisbursementMethodSelection,
  ...LoanFinalAccept,
  ...LoanPaymentReview,
  ...LoanTilaDisclosure,
  ...PaymentReviewTilaAndLoanAgreementCA,
  ...RelinkPreferredBankAccount,
  ...StateDisclosure,
  ...useAcceptLoan,
  ...LoanHistory,
  ...LoanProcessingQuery,
  ...LoanAmount,
  ...LoanPayAmountEntry,
  ...LoanPayConfirm,
  ...LoanPayMethods,
  ...NetworkFailureError,
  ...ProofOfResidence,
  ...LoanCount,
  ...ReschedulePayment,
  ...SelectPaymentToReschedule,
  ...AddressQuery,
  ...CardOfferAvailabilityStatusQuery,
  ...UpgradeToInstallmentPlan,
  ...AcceptLoanAutoPayAgreement,
  ...AcceptPrimaryAccountAgreement,
  ...AddNewAccountToPossible,
  ...AggregatorAccountSelectionForPrimaryAllAccounts,
  ...BankCreateLinkedAccounts,
  ...ReapplicationBank,
  ...useLinkDebitPaymentMethod,
  ...CollectDebitCardNumbersForLoan,
  ...BankAddDebitPaymentMethod,
  ...LatestLoanStatus,
  ...useSetPrimaryAccount,
  ...PrimaryAccountSelectionGQLContainer,
  ...AccountManagementAccounts,
  ...SetLoanAutoPayAccount,
  ...AddressCollection,
  ...UpdateAddressGetOffers,
  ...PhoneNumberInputVerification,
  ...CheckIsPlaidLayerAvailable,
  ...ProcessPlaidProfile,
  ...GetPlaidLinkTokenForLayers,
  ...Profile,
  ...PersonalInformation,
  ...VerifyYourIdentity,
  ...workflowInit,
}
/* AUTO-GENERATED IMPORTS END */

const {allQueries, allQueryOperationNames, allMutations, allMutationOperationNames} =
  parseGeneratedTypes(genTypes)

export const AllColocatedOperationDocuments = genTypes
export const AllColocatedQueryOperations = allQueries
export const AllColocatedQueryOperationNames = allQueryOperationNames
export const AllColocatedMutationOperations = allMutations
export const AllColocatedMutationOperationNames = allMutationOperationNames
